import axios from 'axios'
axios.defaults.withCredentials = true;
// https://r57jsq9y.lc-cn-n1-shared.com/1.1
export function request(config) {
    // 根据环境动态设置 baseURL
    const baseURL = process.env.NODE_ENV === 'production' 
    ? ''  // 生产环境不需要这个了，直接在腾讯云被NGINX代理到django上面去了
    : 'http://127.0.0.1:8000/';                   // 开发环境的 URL
  // 1.创建axios的实例
  const instance = axios.create({
    // 这里是花钱买来的接口，珍惜啊！！！！
    // baseURL: 'https://r57jsq9y.lc-cn-n1-shared.com/1.1',
    // 这是本地测试的，到时候django部署以后需要在正式环境使用
    baseURL: baseURL,
    timeout: 5000,
    withCredentials: true,
  })

  // 2.axios的拦截器
  // 2.1.请求拦截的作用
  instance.interceptors.request.use(config => {
    const csrftoken = document.cookie.match(/csrftoken=([^;]*)/)?.[1];
    if (csrftoken) {
        config.headers['X-CSRFToken'] = csrftoken;
    }
    const token = localStorage.getItem('token')
    if (token){
      config.headers.Authorization = `Token ${token}`;
    }
    console.log(config.url)
    return config
  }, err => {
    console.log(err);
  })

  // 2.2.响应拦截
  instance.interceptors.response.use(res => {
    return res.data
  }, err => {
    return Promise.reject(err);;
  })

  // 3.发送真正的网络请求
  return instance(config)
}
